import { Component, OnInit } from '@angular/core';
import { AutenticacionService } from "../../seguridad/autenticacion.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Constante } from "../../nucleo/constante/Constante";
import { finalize } from "rxjs/operators";
import { Subscription } from "rxjs";
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { TranslateService } from "@ngx-translate/core";
import { MD5 } from "crypto-js";
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';
import { ServicioService } from '../../servicio/servicio.service';
import { CommonComponent } from '../../common/common.component';
import { Utilitario } from '../../nucleo/util/utilitario';

@Component({
  selector: 'app-recupera-pass',
  templateUrl: './recupera-pass.component.html',
  styleUrls: ['./recupera-pass.component.css']
})
export class RecuperaPassComponent extends CommonComponent implements OnInit {
  entidad: any = {};
  mensaje: string;
  codigoLogin: string;
  flag = false;
  showGeneralProgres = false;
  captchaCode: string = null;
  verPass = false;
  clasePass = "password";
  systemTitle = Constante.SYSTEM_TITLE;
  logoMinsa = Constante.LOGO_MINSA;
  tokenGoogle: string = "";
  private subscription: Subscription;
  utilitario = new Utilitario();

  constructor(
    servicio: ServicioService,
    public router: Router,
    private rutaActual: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    public translate: TranslateService,
    public datepipe: DatePipe,
    private modalService: NgbModal,
    toastr: ToastrService
  ) {
    super(servicio, toastr);
    this.setConfigLanguaje();
  }

  ngOnInit() {
  }

  public setConfigLanguaje() {
    let browserLang = localStorage.getItem("languaje");
    if (browserLang === null) {
      browserLang = "es";
    }
    this.translate.use(browserLang.match(/en|fr|qu|es/) ? browserLang : "es");
  }

  resolved(captchaResponse: string) {
    this.captchaCode = captchaResponse;
  }

  recuperaPass(): void {
    this.showGeneralProgres = true;
    this.service
        .postGeneric(
          "usuario/recupera-pass",
          "msopmcovid",
          this.entidad
        )
          .pipe(finalize(() => (this.showGeneralProgres = false)))
          .subscribe(
            (response) => {
              if (response.codigo === Constante.RESPUESTA_OK) {
  
                this.toastr.success(
                  response.mensaje,
                  "Info ",
                  this.utilitario.setOptionsToat()
                );
                //this.entidad.correo = "";
                
              } else {
                if (response.mensaje === "Usuario no encontrado"){
                  response.mensaje = "El correo digitado no se encuentra registrado en el OPPF, comuníquese con el " +
                  "Gestor de su jurisdicción o al correo preciosdigemid@minsa.gob.pe";
                }
                this.toastr.error(
                  response.mensaje,
                  'Error',
                  this.utilitario.setOptionsToat()
                );
              }
            },
            (error: Error) => {
              if (this.toastr.toasts.length === 0) {
                this.toastr.error(
                  Constante.MENSAJE_ERROR_CONEXION,
                  'Error',
                  this.utilitario.setOptionsToat()
                );
              }
            }
          );

  }

  regresar(): void {
    this.router.navigate(["../login"], {
      relativeTo: this.rutaActual,
    });
  }

}
