import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  state,
  style,
  transition,
  animate,
  trigger,
  AUTO_STYLE,
} from "@angular/animations";
import { MenuItems } from "../shared/menu-items/menu-items";
import { AutenticacionService } from "../seguridad/autenticacion.service";
import { Constante } from "../nucleo/constante/Constante";
import { ModalConfirmarSiNoComponent } from "../components/modal-confirmar-si-no/modal-confirmar-si-no.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
}

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.css"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px",
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE,
        })
      ),
      transition("no-block <=> yes-block", [animate("400ms ease-in-out")]),
    ]),
  ],
})
export class AdminLayoutComponent implements OnInit {
  deviceType = "desktop";
  verticalNavType = "expanded";
  verticalEffect = "shrink";
  innerHeight: string;
  isCollapsedMobile = "no-block";
  isCollapsedSideBar = "no-block";
  toggleOn = true;
  windowWidth: number;
  usuario: any = {};
  systemTitle = Constante.SYSTEM_TITLE;
  logoMinsa = Constante.LOGO_MINSA;
  menuList = [];
  principal = [];
  allMenu = [];

  constructor(
    public menuItems: MenuItems,
    public autenticationService: AutenticacionService,
    private modalService: NgbModal
  ) {
    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + "px";
    this.windowWidth = window.innerWidth;
    this.setMenuAttributs(this.windowWidth);
    //this.principal = this.menuItems.getAll();
    this.allMenu = this.menuItems.getAll();
  }

  ngOnInit() {
    this.getDatosUsuario();
    //this.validateMenuItems();
    this.validarMenuPorRol();
  }

  validateCerrarSession(param: string) {
    if (param === "cerrar-sesion") {
      this.openModalConfirmar();
    }
  }
  onClickedOutside(e: Event) {
    if (
      this.windowWidth < 768 &&
      this.toggleOn &&
      this.verticalNavType !== "offcanvas"
    ) {
      this.toggleOn = true;
      this.verticalNavType = "offcanvas";
    }
  }

  getDatosUsuario(): void {
    this.usuario = {
      codigo: this.autenticationService.obtenerDatosUsuario().usuario,
      razonComercial: this.autenticationService.obtenerDatosUsuario()
        .razonComercial,
    };
  }

  validateMenuItems(): void {
    this.principal = this.menuItems.getAll();
    console.log("this.principal[0].main;", this.principal[0].main);
    const menuListV2 = this.principal[0].main;
    this.menuList = [];
    menuListV2.forEach((element) => {
      console.log("this.usuario.codigo", this.usuario.codigo);
      if (this.usuario.codigo !== "20131373237") {
        // corregir esto
        console.log("elemento", element);
        if (element.rol === "ADMINISTRADO") {
          this.menuList.push(element);
        }
      } else {
        console.log("elemento", element);
        this.menuList.push(element);
      }
    });
    this.principal[0].main = this.menuList;
  }

  // MEJORAR ESTO.
  validarMenuPorRol(): void {
    this.principal = this.menuItems.getAll();
    if (this.usuario.codigo !== "20131373237") {
      const menuListV2 = this.principal[0].main;
      menuListV2.forEach((element) => {
        if (element.rol === "ADMINISTRADO") {
          this.menuList.push(element);
        }
      });
      this.principal[0].main = this.menuList;
    } else {
      const allMenu = Constante.MENUITEMS;
      this.principal = allMenu;
    }
  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + "px";
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (
      this.deviceType === "tablet" &&
      this.windowWidth >= 768 &&
      this.windowWidth <= 1024
    ) {
      reSizeFlag = false;
    } else if (this.deviceType === "mobile" && this.windowWidth < 768) {
      reSizeFlag = false;
    }

    if (reSizeFlag) {
      this.setMenuAttributs(this.windowWidth);
    }
  }

  setMenuAttributs(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = "tablet";
      this.verticalNavType = "collapsed";
      this.verticalEffect = "push";
    } else if (windowWidth < 768) {
      this.deviceType = "mobile";
      this.verticalNavType = "offcanvas";
      this.verticalEffect = "overlay";
    } else {
      this.deviceType = "desktop";
      this.verticalNavType = "expanded";
      this.verticalEffect = "shrink";
    }
  }

  toggleOpened() {
    if (this.windowWidth < 768) {
      this.toggleOn =
        this.verticalNavType === "offcanvas" ? true : this.toggleOn;
      this.verticalNavType =
        this.verticalNavType === "expanded" ? "offcanvas" : "expanded";
    } else {
      this.verticalNavType =
        this.verticalNavType === "expanded" ? "collapsed" : "expanded";
    }
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar =
      this.isCollapsedSideBar === "yes-block" ? "no-block" : "yes-block";
  }

  onMobileMenu() {
    this.isCollapsedMobile =
      this.isCollapsedMobile === "yes-block" ? "no-block" : "yes-block";
  }

  logout(): void {
    this.autenticationService.logout();
  }

  openModalConfirmar(): void {
    const data = {
      title: "Cerrar sesión",
      pregunta: "¿Está seguro que desea cerrar sesión?",
    };
    const modalRef: NgbModalRef = this.modalService.open(
      ModalConfirmarSiNoComponent,
      { centered: true, size: "sm", backdrop: "static" }
    );
    modalRef.componentInstance.data = data;

    modalRef.componentInstance.closeMyModal = (response) => {
      modalRef.close();

      if (response) {
        this.logout();
      }
    };
  }
}
